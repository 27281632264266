/** @jsx jsx */

// NOTE: Must be imported first
import 'focus-visible/dist/focus-visible.js';

import type { CSSObject } from '@emotion/core';
import { Global, jsx } from '@emotion/core';
import { Fragment, memo } from 'react';
import type { ThemeProviderProps } from '@balance-web/theme';
import { ThemeProvider, useRawTheme } from '@balance-web/theme';
import { MaxElevationProvider } from '@balance-web/elevate';

import { resetStyles } from './reset-styles';
import type { LinkComponent } from './linkComponent';
import { DefaultLinkComponent, LinkComponentContext } from './linkComponent';

// Core
// ------------------------------

type CoreProps = ThemeProviderProps & {
  linkComponent?: LinkComponent;
  globalStyles?: CSSObject;
};

export const Core = ({
  children,
  linkComponent = DefaultLinkComponent,
  paletteResolver,
  theme,
  themeRaw,
  themeCSSVars,
  globalStyles,
}: CoreProps) => {
  return (
    <LinkComponentContext.Provider value={linkComponent}>
      <MaxElevationProvider>
        <ThemeProvider
          paletteResolver={paletteResolver}
          theme={theme}
          themeCSSVars={themeCSSVars}
          themeRaw={themeRaw}
        >
          <GlobalStyles globalStyles={globalStyles} />
          <App>{children}</App>
        </ThemeProvider>
      </MaxElevationProvider>
    </LinkComponentContext.Provider>
  );
};

// NOTE: only re-render leaf nodes that listen to theme changes

const App = memo((props) => {
  return <Fragment {...props} />;
});
App.displayName = 'App';

// Global styles
// ------------------------------

type GlobalStylesProps = {
  globalStyles?: CSSObject;
};
const GlobalStyles = (props: GlobalStylesProps) => {
  const { palette, typography, themeCSSVars } = useRawTheme();

  return (
    <Fragment>
      <Global styles={resetStyles} />

      {/* focus ring */}
      <Global
        styles={{
          '*:focus': {
            outline: 0,
          },
          '[data-focus-visible-added]': {
            outline: `2px solid ${palette.global.focusRing}`,
            outlineOffset: 2,
          },

          // remove firefox dotted focus-ring
          '& [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring, button:-moz-focusring': {
            outline: 'none !important',
          },
        }}
      />

      {/* defaults */}
      <Global
        styles={[
          {
            body: {
              backgroundColor: palette.background.base,
              fontFamily: typography.fontFamily.body, // TODO: remove

              // optimize legibility
              fontFeatureSettings: '"liga" 1', // Enable OpenType ligatures in IE
              textRendering: 'optimizeLegibility',
              WebkitFontSmoothing: 'antialiased',
              MozOsxFontSmoothing: 'grayscale',
              MozFontFeatureSettings: '"liga" on',
            },
            // TODO: global "selection" styles?
          },
          props.globalStyles,
        ]}
      />

      {/* Inject css vars */}
      <Global styles={themeCSSVars} />
    </Fragment>
  );
};
